.fr-toolbar {
  border: none;
}

.fr-box.fr-basic .fr-wrapper {
  border: none;
}

.fr-second-toolbar {
  border: none;
}
